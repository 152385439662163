import React, { useEffect, useState } from "react";
import "./Portfolio.css";

import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper";

function Portfolio() {
  const [allProjects, setAllProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch("https://portfolio-nxue.onrender.com/getProjects");
        if (response.ok) {
          const data = await response.json();
          setAllProjects(data);
        } else {
          throw new Error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchProjects();
  }, []);

  console.log("projects data", allProjects);

  // const projects = [
  //   {
  //     id: 1,
  //     image: IMG1,
  //     title: "A project Exmp",
  //     github: "https://github.com",
  //     demo: "#",
  //   },
  //   {
  //     id: 1,
  //     image: IMG2,
  //     title: "A project Exmp",
  //     github: "https://github.com",
  //     demo: "#",
  //   },
  //   {
  //     id: 1,
  //     image: IMG3,
  //     title: "A project Exmp",
  //     github: "https://github.com",
  //     demo: "#",
  //   },
  //   {
  //     id: 1,
  //     image: IMG4,
  //     title: "A project Exmp",
  //     github: "https://github.com",
  //     demo: "#",
  //   },
  //   {
  //     id: 1,
  //     image: IMG5,
  //     title: "A project Exmp",
  //     github: "https://github.com",
  //     demo: "#",
  //   },
  //   {
  //     id: 1,
  //     image: IMG6,
  //     title: "A project Exmp",
  //     github: "https://github.com",
  //     demo: "#",
  //   },
  // ];

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>
      <div className="container portfolio__container">
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          navigation
          spaceBetween={40}
          slidesPerView={width > 768 ? 2 : 1}
        >
          {allProjects.map((project) => (
            <SwiperSlide>
              <article className="portfolio__item" key={project.id}>
                <div className="portfolio__item-image">
                  <img src={project.img} alt="a project screenshot" />
                </div>
                <h3>{project.title}</h3>
                <div className="portfolio__item-cta">
                  <a href={project.github} target={"_blank"} className="btn">
                    Github
                  </a>
                  <a
                    href={project.demo}
                    target={"_blank"}
                    className="btn btn-primary"
                  >
                    Live Demo
                  </a>
                </div>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Portfolio;
