import React, { useState } from "react";
import "./Dashboard.css";

import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

export default function Dashboard() {
  const [password, setPassword] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [demoLink, setDemoLink] = useState("");

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // Check if the provided password is correct
    // You can replace the condition with your own password validation logic
    if (password === "ShiroNhL2000077") {
      setShowForm(true);
    } else {
      alert("Incorrect password");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://portfolio-nxue.onrender.com/addProject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          img: imageUrl,
          github: githubLink,
          demo: demoLink,
        }),
      });
      if (response.ok) {
        const savedProject = await response.json();
        console.log("New Project:", savedProject);
        // Reset the form fields
        setTitle("");
        setImageUrl("");
        setGithubLink("");
        setDemoLink("");
      } else {
        throw new Error("Failed to add project");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (!showForm) {
    return (
      <div className="dashboard__container container">
        <h1>Enter Password</h1>
        <form onSubmit={handlePasswordSubmit} className="dashboard__form">
          <label htmlFor="password">Password:</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="toggle-password-btn"
            >
              {showPassword ? (
                <AiFillEyeInvisible size={25} />
              ) : (
                <AiFillEye size={25} />
              )}
            </button>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
        <a href="/" className="dashboard__homeLink">Go home</a>
      </div>
    );
  }

  return (
    <div className="dashboard__container container">
      <h2>Add New Project</h2>
      <form onSubmit={handleSubmit} className="dashboard__form">
        <div>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="text"
            id="imageUrl"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="githubLink">GitHub Link:</label>
          <input
            type="text"
            id="githubLink"
            value={githubLink}
            onChange={(e) => setGithubLink(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="demoLink">Demo Link:</label>
          <input
            type="text"
            id="demoLink"
            value={demoLink}
            onChange={(e) => setDemoLink(e.target.value)}
          />
        </div>
        <button type="submit" className="btn">
          Add Project
        </button>
      </form>
      <a href="/" className="dashboard__homeLink">Go home</a>
    </div>
  );
}
