import React, { useState, useEffect } from "react";
import "./Nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBookAlt } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { IoIosApps } from "react-icons/io";
import { BiMessageSquareDetail } from "react-icons/bi";

function Nav() {
  const [activeLink, setActiveLink] = useState("#");

  // const [width, setWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   setWidth(window.innerWidth);
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;

  //     // Determine the active link based on scroll position
  //     if (scrollTop < 500) {
  //       setActiveLink("#");
  //     } else if (scrollTop >= 500 && scrollTop < 1000) {
  //       setActiveLink("about");
  //     } else if (scrollTop >= 1000 && scrollTop < 2000) {
  //       setActiveLink("experience");
  //     } else if (scrollTop >= 2000 && scrollTop < 3000) {
  //       setActiveLink("services");
  //     } else if (scrollTop >= 3000 && scrollTop < 3500) {
  //       setActiveLink("portfolio");
  //     } else {
  //       setActiveLink("contact");
  //     }
  //   };

  //   // Attach the scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <nav>
      <a
        href="#"
        className={activeLink === "#" ? "active" : ""}
        onClick={() => setActiveLink("#")}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className={activeLink === "about" ? "active" : ""}
        onClick={() => setActiveLink("about")}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={activeLink === "experience" ? "active" : ""}
        onClick={() => setActiveLink("experience")}
      >
        <BiBookAlt />
      </a>
      <a
        href="#services"
        className={activeLink === "services" ? "active" : ""}
        onClick={() => setActiveLink("services")}
      >
        <RiServiceLine />
      </a>
      <a
        href="#portfolio"
        className={activeLink === "portfolio" ? "active" : ""}
        onClick={() => setActiveLink("portfolio")}
      >
        <IoIosApps />
      </a>
      <a
        href="#contact"
        className={activeLink === "contact" ? "active" : ""}
        onClick={() => setActiveLink("contact")}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
}

export default Nav;
