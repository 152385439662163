import React from "react";
import "./Testimonials.css";

import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper";

function Testimonials() {
  const clients = [
    {
      id: 1,
      avtr: AVTR1,
      name: "Lorem ipsum",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, perferendis tempora. Pariatur facere ex molestias.",
    },
    {
      id: 2,
      avtr: AVTR2,
      name: "Lorem ipsum",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, perferendis tempora. Pariatur facere ex molestias.",
    },
    {
      id: 3,
      avtr: AVTR3,
      name: "Lorem ipsum",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, perferendis tempora. Pariatur facere ex molestias.",
    },
    {
      id: 4,
      avtr: AVTR4,
      name: "Lorem ipsum",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, perferendis tempora. Pariatur facere ex molestias.",
    },
  ];
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <div className="container testimonials__container">
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          navigation
          spaceBetween={40}
          slidesPerView={1}
        >
          {clients.map((cli) => (
            <SwiperSlide>
              <article className="testimonial">
                <div className="client__avatar">
                  <img src={cli.avtr} alt="client avatar" />
                </div>
                <h5 className="client__name">{cli.name} </h5>
                <small className="client__review">{cli.review}</small>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonials;
